.card {
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: center;
  justify-content: center;
  text-align: center;
  transition: transform 0.2s;
  backface-visibility: hidden;
  will-change: transform;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 10px;
  background-color: transparent;
  border: none;
}

.course-img {
  height: 210px;
  width: 210px;
  border: 10px solid rgba(0, 0, 0, 0.1);
  outline-color: azure;
  border-radius: 50%;
  overflow: hidden;
}

.banner {
  height: 190px;
  width: 190px;
  border: 10px solid var(--warning);
  outline-color: azure;
  border-radius: 50%;
}
.course-title {
  margin: 0;
  font-weight: 900;
  font-size: 24px;
  font-family: "leela-bold" !important;
  color: var(--warning);
}
.course-subTitle {
  margin: 0;
  font-size: 1rem;
  padding: 0;
  color: var(--white);
  font-weight: 700;
  font-family: "leela-bold" !important;
}
.course-info {
  padding: 10px 15px 10px 15px;
  height: auto;
  width: inherit;
  display: flex;
  flex-direction: column;
}

.candle-icon {
  height: 50px;
  width: 50px;
  margin-top: -40px;
}

.know-more {
  margin-top: 10px;
  padding: 0.5rem 1rem;
  color: var(--white);
  border-radius: 20px;
  border: 1px solid var(--white);
  background-color: transparent;
  outline: none;
  font-weight: 600;
  cursor: pointer;
  &:hover {
    background-color: var(--warning);
    border-color: var(--warning);
    font-weight: 600;
    transition: all 0.3s ease-in;
  }
}

@media (max-width: 768px) {
  .card {
    margin: 10px;
  }
  .course-img {
    height: 100px;
    width: 100px;
    border: 5px solid rgba(0, 0, 0, 0.1);
  }
  .banner {
    height: 90px;
    width: 90px;
    border: 5px solid var(--warning);
  }
  .course-title {
    font-size: 14px;
  }
  .course-subTitle {
    font-size: 14px;
  }
  .course-info {
    height: 60px;
  }
  .candle-icon {
    height: 30px;
    width: 30px;
    margin-top: -25px;
  }
  .know-more {
    padding: 0.2rem 0.5rem;
  }
}

@media only screen and (min-width: 320px) and (max-width: 640px) {
  .course-img {
    height: 54px;
    width: 54px;
    border: 2px solid rgba(0, 0, 0, 0.1);
  }
  .banner {
    height: 50px;
    width: 50px;
    border: 2px solid var(--warning);
  }
  .candle-icon {
    height: 15px;
    width: 15px;
  }
  .know-more {
    padding: 5px 8px;
    font-size: 8px;
  }
  .course-title {
    font-size: 10px;
  }
  .course-info {
    height: 40px;
  }
  .course-subTitle {
    font-size: 8px;
  }
}
