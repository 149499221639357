@import "../../App.css";

.container-fluid {
  background-color: var(--bg-pink);
}

.team-wrapper {
  background: url("../../assets/img/bg-homeCourses-crop.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  margin-top: -0.7rem;
}
.first {
  margin: 0;
  padding: 0;
  font-family: "shadow-light" !important;
  font-size: 4rem;
  background: linear-gradient(180deg, rgb(230, 143, 22), rgb(206, 111, 29));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-align: center;
  color: transparent;
}
.remaining {
  font-size: 3rem;
  font-weight: 900;
  color: var(--white);
  margin-top: -2rem;
}
.approach {
  padding: 0rem 5rem 0px 5rem;
  img {
    border-top-left-radius: 16vw;
    border-top-right-radius: 16vw;
  }
}
.dividerOrange {
  width: 95%;
  align-self: center;
  margin-top: -1rem;
}
.intro {
  color: var(--dark);
}
.team-list {
  margin: 0px;
  li {
    background-color: transparent;
    border: none;
    border-bottom: 2px solid var(--gray);
    text-align: justify;
    hyphens: auto;
    color: var(--white);
    h2 {
      font-size: 24px;
    }
    h4 {
      font-size: 20px;
    }
  }
}
.border-img {
  height: 160px;
  width: 160px;
  border: 8px solid rgba(0, 0, 0, 0.1);
  outline-color: azure;
  border-radius: 50%;
}
.team-img {
  height: 144px;
  width: 144px;
  border-radius: 50%;
  border: 5px solid var(--warning);
}

.list-row-view {
  display: flex;
  justify-content: center;
  gap: 10px;
  padding: 0px 20px;
}

.verticalDivider {
  background-color: var(--warning);
  border-top-left-radius: 14px;
  border-top-right-radius: 14px;
  width: 5px;
  opacity: 50%;
}

.content {
  height: 190px;
  overflow: hidden;
  transition: height 0.3s ease;
  font-family: "leela-regular";
}

.content.expanded {
  height: auto;
}

.read-more {
  color: var(--warning);
  cursor: pointer;
  text-decoration: underline;
}

@media only screen and (min-width: 320px) and (max-width: 640px) {
  .approach {
    padding: 0.5rem 1rem 0px 1rem;
  }
  .team-list {
    margin: 0px;
    li {
      h2 {
        font-size: 14px;
      }
      h4 {
        font-size: 12px;
      }
    }
  }

  .border-img {
    height: 110px;
    width: 120px;
    align-self: center;
    border: 4px solid rgba(0, 0, 0, 0.1);
  }
  .team-img {
    height: 100px;
    width: 100px;
    border: 2px solid var(--warning);
  }
  .remaining {
    margin-top: -0.5rem;
  }
  .list-row-view {
    display: grid;
    padding: 0px 10px;
  }
  .item-detail {
    p {
      font-size: 12px;
    }
  }
  .content {
    height: auto;
  }
  .read-more {
    display: none;
  }
}

@media only screen and (min-width: 641px) and (max-width: 767px) {
  .approach {
    padding: 1rem 2rem 0px 2rem;
  }
  .team-list {
    margin: 0px;
    li {
      h2 {
        font-size: 16px;
      }
      h4 {
        font-size: 14px;
      }
    }
  }
  .remaining {
    margin-top: -2rem;
  }

  .border-img {
    height: 110px;
    width: 120px;
    align-self: center;
    border: 4px solid rgba(0, 0, 0, 0.1);
  }
  .team-img {
    height: 100px;
    width: 100px;
    border: 2px solid var(--warning);
  }
  .content {
    height: auto;
  }
  .read-more {
    display: none;
  }
}
