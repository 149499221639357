.feedback-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  background: linear-gradient(rgba(0, 88, 164, 1), rgba(0, 88, 164, 0.8));
  position: relative;
  overflow: hidden;
}

.title {
  margin: 0;
  padding: 0;
  font-family: "shadow-light" !important;
  font-size: 60px;
  background: linear-gradient(180deg, rgb(230, 143, 22), rgb(206, 111, 29));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-align: center;
  color: transparent;
}
.subtitle {
  margin: 0;
  padding: 0;
  color: var(--white);
  text-align: center;
  font-weight: 900;
  font-size: 40px;
  margin-top: -10px;
}

.circle {
  position: absolute;
  border: 10px solid yellow;
  border-radius: 50%;
  opacity: 40%;
}
.circle1 {
  width: 150px;
  height: 150px;
  top: -70px;
  right: 20px;
}
.circle2 {
  width: 80px;
  height: 80px;
  top: 35px;
  right: 170px;
}

.circle3 {
  width: 40px;
  height: 40px;
  bottom: 10px;
  left: 10px;
}
.circle4 {
  width: 80px;
  height: 80px;
  bottom: 40px;
  left: 40px;
}
.react-multiple-carousel__arrow {
  background: var(--warning);
}
@media only screen and (min-width: 768px) and (max-width: 990px) {
  .title {
    font-size: 60px;
  }
  .subtitle {
    font-size: 40px;
  }

  .feedback-card {
    p {
      padding: 0px 40px;
    }
  }
}

@media only screen and (min-width: 320px) and (max-width: 640px) {
  .title {
    font-size: 24px;
  }

  .subtitle {
    font-size: 16px;
  }

  .feedback-card {
    p {
      padding: 0px 40px;
    }
  }

  .circle {
    border: 5px solid yellow;
  }
  .circle1 {
    width: 80px;
    height: 80px;
    top: -50px;
    right: 0px;
  }
  .circle2 {
    width: 40px;
    height: 40px;
    top: 20px;
    right: 60px;
  }

  .circle3 {
    width: 30px;
    height: 30px;
    bottom: 8px;
    left: 8px;
  }
  .circle4 {
    width: 44px;
    height: 44px;
    bottom: 30px;
    left: 30px;
  }
}
