.divider-gray {
  background: url(../../assets/img/deviderGrey.png);
  color: var(--white);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 81vw;
  height: 40px;
  align-self: center;
  margin-left: 9vw;
  margin-top: -2.5vh;
}

.contact-grid {
  padding: 10vh 10vw;
  h2 {
    color: var(--green);
  }
  input {
    height: 6vh;
    width: 100%;
    padding: 2vh;
    margin-bottom: 4vh;
    border-radius: 4px;
    border: 1px solid var(--gray);
  }
  ::placeholder {
    color: var(--gray);
    opacity: 0.5; /* Firefox */
  }
  textarea {
    width: 100%;
    padding: 8px;
    height: 50px;
    border-radius: 4px;
    border: 1px solid var(--gray);
  }
  button {
    height: 40px;
    background-color: var(--green);
    color: var(--white);
    border: none;
    border-radius: 8px;
    padding: 5px 10px 5px 10px;
    font-size: 14px;
    font-weight: 900;
    margin-top: 20px;
  }
}

.bg-img-conact {
  width: 100vw;
}
