.why-div {
  background-color: var(--offWhite);
  display: flex;
  flex-direction: column;
  width: 100%;
}
.why-container {
  padding-right: 15px;
  padding-left: 15px;
}

.why-row {
  padding-left: 60px;
  padding-right: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}
.secondary {
  background-color: var(--secondary);
}

.btn-icon {
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  border-radius: 10px;
}

.obj-fit {
  object-fit: cover;
  width: 300px;
}

.why-img {
  vertical-align: middle;
  border-style: none;
  width: auto;
  height: auto;
}

.why-desc {
  margin-top: 0%;
  font-weight: 700;
  color: var(--dark);
}
.why-title {
  margin-top: 0%;
  margin-bottom: 5px;
  font-size: 20px;
  font-weight: 700;  
  color: var(--primary);
}
.why-list {
  flex: 1;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}
.why-devider {
  width: 89%;
  align-self: center;
}

.title {
  margin: 0;
  padding: 0;
  font-size: 60px;
  font-family: "shadow-light" !important;
  background: linear-gradient(180deg, rgb(230, 143, 22), rgb(206, 111, 29));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-align: center;
  color: transparent;
}

.sub-title {
  margin: 0;
  padding: 0;
  font-size: 40px;
  font-weight: 900;
  font-family: "leela-bold" !important;
  color: var(--primary);
  text-align: center;
  margin-top: -10px;
}
@media only screen and (min-width: 768px) and (max-width: 990px) {
  .btn-icon {
    height: 50px;
    width: 50px;
    border-radius: 10px;
  }
  .why-desc {
    font-size: 10px;
    font-weight: 400;
  }
  .why-title {
    font-size: 10px;
    margin-bottom: 0.5rem;
    font-weight: 600;
  }
  .obj-fit {
    width: 90%;
  }

  .title {
    font-size: 40px;
  }
  .sub-title {
    font-size: 30px;
  }
}
@media only screen and (min-width: 320px) and (max-width: 640px) {
  .btn-icon {
    height: 35px;
    width: 35px;
    border-radius: 6px;
  }
  .why-row {
    padding-left: 5px;
    padding-right: 5px;
  }
  .why-title {
    font-size: 14px;
    margin-bottom: 5px;
    font-weight: 700;
  }
  .title {
    font-size: 24px;
  }
  .obj-fit {
    width: 50%;
  }
  .sub-title {
    font-size: 16px;
  }
}
