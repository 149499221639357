@import "font-awesome/css/font-awesome.min.css";

@font-face {
  font-family: "varela";
  src: local("varela"),
    url(./assets/fonts/VarelaRound-Regular.ttf) format("truetype");
}

@font-face {
  font-family: "leela-regular";
  src: local("leela-regular"),
    url(./assets/fonts/LEELAWAD.TTF) format("truetype");
}
@font-face {
  font-family: "leela-bold";
  src: local("leela-bold"), url(./assets/fonts/LEELAWDB.TTF) format("truetype");
}

@font-face {
  font-family: "shadow-light";
  src: local("shadow-light"),
    url(./assets/fonts/ShadowsIntoLight-Regular.ttf) format("truetype");
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #6b6a75;
  text-align: left;
  font-family: "leela-regular";
}
