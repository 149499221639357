.about-home-title {
  margin: 0;
  padding: 0;
  font-family: "shadow-light" !important;
  background: linear-gradient(180deg, rgb(230, 143, 22), rgb(206, 111, 29));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  color: transparent;
  font-size: 60px;
}

.about-subtitle {
  margin: 0;
  padding: 0;
  font-family: "leela-bold" !important;
  color: var(--primary);
  font-size: 40px;
}

.aboutHome-continer p {
  text-wrap: balance;
  color: var(--dark);
}

.intro {
  font-size: 1rem;
  color: var(--dark);
}


@media only screen and (min-width: 768px) and (max-width: 990px) {
  
  .about-home-title {
    font-size: 40px;
  }
  .about-subtitle {
    font-size: 30px;
  }
  p {
    font-size: 1rem;
  }
}
@media only screen and (min-width: 320px) and (max-width: 640px) {
  
  .about-home-title {
    font-size: 24px;
  }
  .about-subtitle {
    font-size: 18px;
  }
  p {
    font-size: 0.5rem;
  }
}
