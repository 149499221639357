.course-container {
  background-color: var(--primary);
  background: url("../../assets/img/bg-homeCourses.png");
}
.course {
  margin: 0;
  padding: 0;
  font-family: "shadow-light" !important;
  background: linear-gradient(180deg, rgb(230, 143, 22), rgb(206, 111, 29));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  color: transparent;
  font-size: 60px;
}
.height-auto{
  height:auto
}
.course-sub-title {
  margin: 0;
  padding: 0;
  font-weight: 900;
  font-family: "leela-bold" !important;
  color: var(--white);
  font-size: 40px;
}
.carousel-container-div {
  padding: 0rem 3rem;
}

.devider-o {
  width: 100%;
  padding: 0px 5.5vw;
  align-items: center;
  background-color: var(--primary);
  img {
    width: 100%;
  }
}
@media (max-width: 768px) {
  .course {
    font-size: 40px;
  }
  .course-sub-title {
    font-size: 30px;
  }
  .carousel-container-div {
    padding: 1rem;
  }
}


@media only screen and (min-width: 320px) and (max-width: 640px) {
  .course {
    font-size: 24px;
  }
  .course-sub-title {
    font-size: 16px;
  }
  .carousel-container-div {
    padding: 0.5rem;
  }
}