.video-container {
  padding: 0.5rem 2rem;
  background-color: var(--bg-pink);
}

video {
  width: 100%;
}
.video-title {
  font-weight: 700;
  display: flex;
  margin-bottom: 10px;
  color: var(--dark);
  font-size: 1.5rem;
}

.session-list {
  height: calc(100vh - 100px);
  overflow-y: auto;
}
.video-list {
  border: 0.5px solid var(--primary);
  padding: 0.5rem;
  border-radius: 0.5rem;
  margin-bottom: 0.5rem;
  background-color: var(--white);
  video {
    height: 18vh;
    width: 100%;
    pointer-events: none;
    border-radius: 10px;
  }
  &:hover {
    cursor: pointer;
  }
}

.video-item {
  margin: 0;
  padding: 0;
  font-weight: 600;
  color: var(--dark);
  small {
    color: var(--gray);
  }
}
@media (max-width: 768) {
  .video-container {
    padding: 2rem;
  }
}
@media (max-width: 375px) {
  .video-container {
    padding: 1rem;
  }
}
