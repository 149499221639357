@import "../../App.css";

.mainHeader {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  position: fixed;
  top: 0;
  width: 100%;
  box-shadow: 0 8px 6px -6px var(--gray);
  z-index: 1001;
}
.sticky-header {
  position: sticky; /* or use fixed for it to always be visible */
  top: 0; /* sticks to the top */
  z-index: 1001; /* ensures it stays above other elements */
}
.zIndex {
  z-index: 0 !important;
}
.navbar-nav .nav-link.active {
  color: var(--primary);
  font-weight: 700;
}

.navbar-nav .nav-link:hover {
  color: var(--warning);
  font-weight: 700;
}
