@import "../../App.css";

.container-course {
  background: url('../../assets/img/bg-homeCourses-crop.png');
  background-size: cover; 
  background-position: center; 
  background-repeat: no-repeat;
  height: calc(100vh - 78px);
}

.container-h {
  height: calc(100vh - 165px);
  overflow-y: auto;
}
.search {
  margin: 0px 5vw;
  padding: 20px 0px;
}

.search input {
  width: 100%;
  border: 1px solid var(--gray-light);
  border-radius: 20px 20px 20px 20px;
  font-size: 14px;
  background-color: var(--white);
  background-image: url("../../assets/img/search.png");
  background-size: 20px 20px;
  background-position: 10px 10px;
  background-repeat: no-repeat;
  padding: 12px 20px 10px 35px;
}
.search-container input:focus {
  border: 1px solid var(--gray);
  outline: none;
}

.courses {
  padding: 20px;
  border-radius: 8px;
}
.grid-container {
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 20px 30px;
  display: grid;
  grid-auto-rows: min-content;
}

@media only screen and (min-width: 320px) and (max-width: 640px) {
  .course-info {
    padding: 10px 0px 0px 0px;
    margin-bottom: 5px;
  }
  .grid-container {
    grid-template-columns: repeat(auto-fill, minmax(115px, 2fr));
    gap: 10px 0px;
    display: grid;
    grid-auto-rows: min-content;
  }
}
