.detail-box {
  color: var(--white);
  position: relative;
}

.desc-div {
  position: absolute;
  top: 50px;
  left: 490px;
}

.text-container {
  display: flex;
  flex-direction: column;
  align-items: baseline;
}

.text-container-center {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.first-word {
  margin: 0;
  padding: 0;
  font-family: "shadow-light" !important;
  background: linear-gradient(180deg, rgb(230, 143, 22), rgb(206, 111, 29));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-align: center;
  color: transparent;
}

.remaining-text {
  font-weight: 900;
}

.card-details-wrapper {
  border-radius: 1rem;
  padding: 2rem;
  background-color: var(--white);
}

.list-item {
  display: flex;
  width: 100%;
  border-bottom: 1px solid var(--gray-light);
  padding: 10px 0;
  align-items: center;
}

.item-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  width: 20px;
  height: 20px;
  margin-right: 10px;
}

.item-name {
  display: flex;
  color: var(--gray-dark);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.item-value {
  display: flex;
  margin-left: auto;
  color: var(--dark);
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.card-detail {
  width: 100%;
  border: 2px solid var(--gray);
  border-radius: 20px;
  overflow: hidden;
  right: 5%;
  color: var(--dark) !important;
  padding: 15px;
}

.btn-enroll-now {
  width: 100%;
  height: 40px;
  margin-top: 10px;
  background-color: var(--orange);
  align-self: center;
  font-size: 14px;
  font-weight: 900;
  color: var(--white);
  border: none;
}

.bullet-img {
  width: 4vh;
  margin-bottom: 10px;
}

.txt-justify {
  text-align: justify;
  color: var(--dark);
}

.sub-section {
  padding: 0px 20px 10px 20px;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.rupee {
  font-size: 1.5rem;
  font-weight: 900;
  text-align: start;
}

.li-learn {
  padding: 0px;
}

.custom-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
  overflow: auto;
  text-align: left;
  font-size: 14px;
  font-weight: 600;
  color: var(--dark);
}

.main-li {
  position: relative;
  padding-left: 30px;
  margin-bottom: 8px;
  cursor: pointer;
}

.child-li {
  font-size: 12px;
  font-weight: 400;
}

.main-li::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 20px;
  height: 20px;
  background-image: url("../../assets/img/bulletPoint.png");
  background-size: cover;
  background-repeat: no-repeat;
}

.courseDescription {
  font-size: 14px;
  font-weight: 400;
}
@media only screen and (min-width: 320px) and (max-width: 640px) {
  .desc-div {
    top: 10px;
    left: 40%;
  }

  .first-word {
    font-size: 16px;
    font-weight: 700;
  }

  .remaining-text {
    font-size: 14px;
  }
}

@media only screen and (min-width: 641px) and (max-width: 767px) {
  .desc-div {
    position: absolute;
    top: 20px;
    left: 40%;
  }

  .first-word {
    font-size: 24px;
    font-weight: 900;
  }

  .remaining-text {
    font-size: 22px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 990px) {
  .desc-div {
    position: absolute;
    top: 20px;
    left: 40%;
  }
}
