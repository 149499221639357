.container-d-fluid {
  background-color: var(--white);
  text-align: center;
  height: auto;
  padding: 20px;
}

.blog-d-container {
  text-align: left;
  margin-top: 10vh;
  padding: 0px 20px 0px 150px;
  span {
    text-align: justify;
  }
}
.blog-img {
  margin-bottom: 26px;
}
.title-d-container {
  margin-top: 20px;
}
.add-container {
  margin-top: 10vh;
  padding: 0px 75px;
}

.title-container {
  color: var(--white);
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  h2 {
    position: absolute;
    font-weight: 700;
  }
}

/* Media Queries */
@media (max-width: 1200px) {
  .container-d-fluid {
    padding: 20px;
  }

  .blog-container {
    padding: 0px 0px 0px 20px;
  }
}

@media (max-width: 992px) {
  .blog-container .add-container {
    padding: 20px 20px;
    margin-top: 20px;
  }
  .title-d-container {
    margin-top: 10px;
  }
  .blog-d-container {
    margin-top: 8vh;
    padding: 0px 20px 0px 120px;
  }
}

@media (max-width: 768px) {
  .container-d-fluid {
    padding: 10px;
  }

  .blog-container .add-container {
    padding: 20px 20px;
    margin-top: 10px;
  }
  .blog-d-container {
    margin-top: 6vh;
    padding: 0px 20px 0px 100px;
  }
  .title-d-container {
    margin-top: 10px;
  }
  .title-container {
    h2 {
      font-size: 24px;
    }
  }
}

@media (max-width: 576px) {
  .blog-container,
  .add-container {
    padding: 20px 20px;
    margin-top: 10px;
  }
  .blog-d-container {
    margin-top: 5vh;
    padding: 0px 20px 0px 20px;
  }
  .title-d-container {
    margin-top: 10px;
  }
  .title-container {
    h2 {
      font-size: 16px;
    }
  }
}

@media (max-width: 350px) {
  .blog-d-container {
    margin-top: 2vh;
    padding: 0px 20px 0px 20px;
  }
  .title-container {
    h2 {
      font-size: 12px;
    }
  }
}
