.feedback-card {
  color: white;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  -webkit-box-shadow: -5px -10px 10px 0px rgba(0, 0, 0, 0.5);
  -moz-box-shadow: -5px -10px 10px 0px rgba(0, 0, 0, 0.5);
  box-shadow: -5px -10px 10px 0px rgba(0, 0, 0, 0.5);
  height: auto;
}

.feedback-img {
  height: 160px;
  width: 160px;
  border: 8px solid rgba(0, 0, 0, 0.1);
  outline-color: azure;
  border-radius: 50%;
  overflow: hidden;
}

.feedback-img img {
  height: 144px;
  width: 144px;
  border: 6px solid var(--warning);
  outline-color: azure;
  border-radius: 50%;
}

.feedback-card p {
  font-family: "leela-regular" !important;
  margin: 0;
  text-align: justify;
  padding: 0 30px;
}

.name {
  margin: 0;
  padding: 0;
  font-size: 1.5rem;
}

.comma {
  height: 40px;
  width: 40px;
  margin-top: -34px;
}

.i-comma {
  height: 24px;
  width: 24px;
  margin: 10px;
}
.designation {
  font-size: 12px;
  font-weight: 400;
}
@media only screen and (min-width: 768px) and (max-width: 990px) {
  .feedback-img {
    height: 110px;
    width: 110px;
    border: 5px solid rgba(0, 0, 0, 0.1);

    img {
      height: 100px;
      width: 100px;
      border: 5px solid var(--warning);
    }
  }

  .i-comma {
    height: 16px;
    width: 16px;
  }

  .comma {
    height: 35px;
    width: 35px;
    margin-top: -25px;
  }
  .name{
    font-size: 14px;
  }
  .feedback-card {
    p {
      font-size: 10px;
      height: 70px;
    }

    
  }
}
