.login-container {
  height: calc(100vh - 110px);
  img {
    width: 100%;
    height: calc(100vh - 85px);
  }
}
.login-box {
  width: 30vw;
  height: calc(100vh - 132px);
  overflow: hidden;
  border-radius: 10px;
  box-shadow: 5px 20px 50px var(--dark);
  background: var(--gray-light);
  position: absolute;
  top: 100px;
  right: 15px;
}

#chk {
  display: none;
}
#forget-chk {
  display: none;
}

.signup {
  position: relative;
  width: 100%;
  height: 100%;
}

.signup button {
  background-color: var(--orange) !important;
}

.login-div label {
  color: var(--dark);
  margin: 10px auto;
  justify-content: center;
  display: flex;
  font-weight: bold;
  cursor: pointer;
  transition: 0.5s ease-in-out;
}

.login-box input {
  width: 60%;
  height: 5vh;
  background-color: var(--white);
  justify-content: center;
  display: flex;
  margin: 10px auto;
  padding: 12px;
  outline: none;
  border-radius: 5px;
  border: 1px var(--gray) solid;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}

.login-box button {
  width: 60%;
  height: 6vh;
  margin: 10px auto;
  justify-content: center;
  display: block;
  color: var(--white);
  background: var(--primary);
  font-size: 1em;
  font-weight: bold;
  outline: none;
  border: none;
  border-radius: 5px;
  transition: 0.2s ease-in;
  cursor: pointer;
}

button:hover {
  background: var(--primary);
}

.login {
  height: 500px;
  background: linear-gradient(rgba(0, 88, 164), rgba(0, 129, 200));
  border-radius: 50% 50% 25% 25% / 20% 20% 0% 0%;
  transform: translateY(-65px);
  transition: 0.8s ease-in-out;
}

.login button {
  border: 2px solid var(--white);
  background-color: var(--white);
  color: var(--primary);
}

.login label {
  color: var(--white);
  transform: scale(0.6);
}

.email-input {
  margin-top: 20%;
}
.forgot-pass {
  font-size: 12px;
  font-weight: 500;
}
#chk:checked ~ .login {
  transform: translateY(-440px);
}

#chk:checked ~ .signup button {
  visibility: hidden;
}

#chk:checked ~ .signup input {
  visibility: hidden;
}

#chk:checked ~ .login label {
  transform: scale(1);
}

#chk:checked ~ .signup label {
  transform: scale(0.5);
}

.forget-pass {
  height: 100vh;
  background: linear-gradient(180deg, rgb(230, 143, 22), rgb(206, 111, 29));
  transform: translateY(-70px);
  transition: transform 0.8s ease-in-out;
  padding-top: 2vh;
  label {
    color: var(--white);
  }
  a {
    font-size: 16px;
    font-weight: 700;
  }
}

#forget-chk:checked ~ .forget-pass {
  transform: translateY(-1015px);
}

@media only screen and (min-width: 320px) and (max-width: 640px) {
  .login-box {
    width: 90%;
    top: 125px;
    right: 0;
    left: 0;
    bottom: 0;
    margin: 0 auto;
    height: calc(100vh - 250px);
  }
  .login-box input {
    width: 85%;
  }
  .login-box button {
    width: 85%;
    height: 5vh;
  }
  .login {
    transform: translateY(-55px);
    border-radius: 50% 50% 25% 25% / 14% 14% 0% 0%;
  }
  #chk:checked ~ .login {
    transform: translateY(-55vh);
  }
  #forget-chk:checked ~ .forget-pass {
    transform: translateY(-137vh);
  }
}
@media only screen and (min-width: 641px) and (max-width: 767px) {
  .login-box {
    width: 90%;
    top: 125px;
    right: 0;
    left: 0;
    bottom: 0;
    margin: 0 auto;
  }
  .login-box input {
    width: 85%;
  }
  .login-box button {
    width: 85%;
  }
  .login {
    border-radius: 50% 50% 25% 25% / 25% 25% 0% 0%;
    form {
      margin-top: 20px;
    }
  }
  #chk:checked ~ .login {
    transform: translateY(-380px);
  }
  #forget-chk:checked ~ .forget-pass {
    transform: translateY(-940px);
  }
}

@media only screen and (min-width: 768px) and (max-width: 990px) {
  .login-box {
    top: 125px;
    right: 15px;
    margin: 0 auto;
  }

  .login {
    border-radius: 50% 50% 25% 25% / 12% 12% 0% 0%;
    form {
      margin-top: 20px;
    }
  }
  #chk:checked ~ .login {
    transform: translateY(-380px);
  }
  #forget-chk:checked ~ .forget-pass {
    transform: translateY(-960px);
  }
}
