.text-wrapper {
  top: 78px !important;
}
.running-text-container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
}

.dot {
  background: var(--white);
  border-radius: 50%;
  display: inline-block;
  height: 14px;
  width: 14px;
  margin-left: 4px;
  margin-right: 10px;
}

.running-text {
  background-color: var(--primary);
  color: var(--white);
  font-weight: 700;
  font-size: 16px;
  padding: 10px 0px;
  &:hover {
    cursor: pointer;
  }
}

.container-fluid {
  background-color: var(--white);
  text-align: center;
  height: auto;
  padding-right: 0;
  padding-left: 0;
}

.title-container {
  color: var(--white);
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  h2 {
    position: absolute;
    font-weight: 700;
  }
}

.latest-blog-container {
  display: grid;
  grid-template-columns: 2fr 1fr;
  gap: 30px;
  padding: 10px;
  cursor: pointer;
}

.main-news {
  position: relative;
}

.main-news img {
  width: 100%;
  height: auto;
  border-radius: 8px;
}

.main-news .category {
  position: absolute;
  bottom: 10px;
  left: 10px;
  background: red;
  color: white;
  padding: 5px 10px;
  font-size: 14px;
  border-radius: 4px;
}

.main-news .headline {
  margin-top: 10px;
  font-size: 18px;
  font-weight: bold;
  line-height: 1.4;
}

.side-news {
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: 340px;
  overflow-y: auto;
  overflow-x: hidden;
  scrollbar-width: none;
}

.side-item {
  display: flex;
  align-items: center;
  gap: 10px;
}

.side-item img {
  width: 150px;
  height: 100px;
  object-fit: cover;
  border-radius: 8px;
  border: 1px solid var(--gray-light);
}

.side-item .text {
  font-size: 14px;
  line-height: 1.2;
}

.blog-container {
  text-align: left;
  margin-top: 10vh;
  padding: 0px 0px 0px 65px;
  h1 {
    margin: 20px;
    color: var(--primary);
  }
}

.blogBox {
  background-color: #fff;
  box-shadow: 0 3px 20px rgba(178, 171, 194, 0.8);
  margin: 0 0px 35px 0px;
  border-radius: 5px;
  position: relative;
  box-sizing: border-box;
  overflow: hidden;
}

.imgWrap {
  height: 400px;
  width: 100%;
  position: relative;
  border-radius: 5px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

.blogHasImg {
  padding: 30px;
  color: #fff;
  position: absolute;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 0 0 5px 5px;
  h3 {
    color: var(--yellow);
  }
}

.readMore {
  background-color: var(--primary);
  color: #fff;
  border: 1px solid var(--primary);
  padding: 7px 20px;
  font-size: 14px;
  line-height: 14px;
  border-radius: 24px;
  text-decoration: none;
  transition: all 0.5s ease;
  &:hover {
    background-color: var(--white);
    border: 1px solid var(--white);
    color: var(--primary);
    cursor: pointer;
    transition: all 0.5s ease;
  }
}

.blogDetailWrapper {
  padding: 30px;
  color: var(--black);
  h3 {
    color: var(--yellow);
  }
}

.blogDetail {
  margin-top: 25px;
  color: var(--black);
  p {
    font-size: 16px;
  }
  a {
    background-color: #f8f8f8;
    color: #a0a0a0;
    border: 1px solid #f8f8f8;
    padding: 7px 20px;
    font-size: 14px;
    line-height: 14px;
    border-radius: 24px;
    transition: all 0.5s ease;
    text-decoration: none;
    &:hover {
      border-color: var(--primary);
      cursor: pointer;
      transition: all 0.5s ease;
    }
  }
}

.add-container {
  margin-top: 10vh;
  padding: 0px 75px;
}

.latest-img {
  height: 350px;
}

/* Media Queries */
@media (max-width: 1200px) {
  .container-fluid {
    padding-right: 0;
    padding-left: 0;
    padding: 0;
  }

  .blog-container {
    padding: 0px 0px 0px 20px;
  }

  .imgWrap {
    height: 250px;
  }
}

@media (max-width: 992px) {
  .blogBox {
    margin: 0 0 20px 0;
  }

  .imgWrap {
    height: 300px;
  }

  .blog-container .add-container {
    padding: 20px 20px;
    margin-top: 20px;
  }

}

@media (max-width: 768px) {
  .latest-img {
    height: 300px;
  }
  .text-wrapper {
    top: 84px !important;
  }
  .container-fluid {
    padding-right: 0;
    padding-left: 0;
    padding: 0;
  }

  .blogBox {
    margin-bottom: 15px;
  }

  .imgWrap {
    height: 200px;
  }

  .blog-container .add-container {
    padding: 20px 20px;
    margin-top: 10px;
  }

  .blogDetailWrapper {
    padding: 15px;
  }

  .container {
    grid-template-columns: 1fr;
  }

  .main-news .headline {
    font-size: 16px;
  }

  .side-item {
    flex-direction: column;
    align-items: flex-start;
    cursor: pointer;
  }

  .side-item img {
    width: 100%;
    height: auto;
  }
}

@media (max-width: 576px) {
  .latest-img {
    height: 150px;
  }
  .text-wrapper {
    top: 72px !important;
  }
  .imgWrap {
    height: 150px;
  }

  .blogDetail {
    p {
      font-size: 12px;
    }
  }
  .running-text {
    font-size: 14px;
    padding: 5px 0;
  }

  .dot {
    height: 10px;
    width: 10px;
  }

  .blogBox {
    margin: 0 0 10px 0;
  }

  .blog-container,
  .add-container {
    padding: 20px 20px;
    margin-top: 10px;
  }

  .blogHasImg h3 {
    font-size: 18px;
  }

  .blogHasImg span {
    font-size: 12px;
  }
  .blogHasImg p {
    font-size: 12px;
  }

  .readMore {
    padding: 5px 10px;
    font-size: 12px;
  }
}
