.closeButton {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.innerHtml {
  overflow-y: scroll;
  height: 90%;
  color: var(--dark);
}